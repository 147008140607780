/**
 * @generated SignedSource<<5e2d781cd0a36763ddd9290bc2fd3daa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductKind = "License";
export type productsTable_QueryFragmentRefetch$variables = {
  after?: string | null;
  before?: string | null;
  first?: number | null;
  inKinds?: ReadonlyArray<ProductKind> | null;
  isHiddenOpt?: boolean | null;
  last?: number | null;
  titleOpt?: string | null;
};
export type productsTable_QueryFragmentRefetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"productsTable_QueryFragment">;
};
export type productsTable_QueryFragmentRefetch = {
  response: productsTable_QueryFragmentRefetch$data;
  variables: productsTable_QueryFragmentRefetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "before"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": [
      "License"
    ],
    "kind": "LocalArgument",
    "name": "inKinds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isHiddenOpt"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "last"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "titleOpt"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "inKinds",
    "variableName": "inKinds"
  },
  {
    "kind": "Variable",
    "name": "isHiddenOpt",
    "variableName": "isHiddenOpt"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "titleOpt",
    "variableName": "titleOpt"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "productsTable_QueryFragmentRefetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "productsTable_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "productsTable_QueryFragmentRefetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "ProductsConnection",
                "kind": "LinkedField",
                "name": "SearchProducts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isHidden",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "netPrice",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "isHiddenOpt",
                  "titleOpt",
                  "inKinds"
                ],
                "handle": "connection",
                "key": "productsTable_SearchProducts",
                "kind": "LinkedHandle",
                "name": "SearchProducts"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5fc79a19a5db4c18aa995fff56ef161b",
    "id": null,
    "metadata": {},
    "name": "productsTable_QueryFragmentRefetch",
    "operationKind": "query",
    "text": "query productsTable_QueryFragmentRefetch(\n  $after: String = null\n  $before: String = null\n  $first: Int\n  $inKinds: [ProductKind!] = [License]\n  $isHiddenOpt: Boolean\n  $last: Int\n  $titleOpt: String\n) {\n  ...productsTable_QueryFragment_3z03D7\n}\n\nfragment ProductActionColumn_ProductFragment on Product {\n  id\n}\n\nfragment ProductIdColumn_ProductFragment on Product {\n  id\n}\n\nfragment ProductIsHiddenColumn_ProductFragment on Product {\n  isHidden\n}\n\nfragment ProductTitleColumn_ProductFragment on Product {\n  title\n}\n\nfragment productPriceColumn_ProductFragment on Product {\n  netPrice\n}\n\nfragment productsTable_ProductFragment on Product {\n  ...ProductIdColumn_ProductFragment\n  ...ProductTitleColumn_ProductFragment\n  ...ProductIsHiddenColumn_ProductFragment\n  ...productPriceColumn_ProductFragment\n  ...ProductActionColumn_ProductFragment\n}\n\nfragment productsTable_QueryFragment_3z03D7 on Query {\n  Admin {\n    Billing {\n      SearchProducts(isHiddenOpt: $isHiddenOpt, titleOpt: $titleOpt, after: $after, before: $before, first: $first, last: $last, inKinds: $inKinds) {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          cursor\n          node {\n            ...productsTable_ProductFragment\n            id\n            __typename\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "353868e1f847d406d1abe07813b93fa0";

export default node;
