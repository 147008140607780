/**
 * @generated SignedSource<<d07f8bb4c039b4c83736bae37876cdee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SetAccountNameInput = {
  clientMutationId?: string | null;
  id: string;
  name: string;
};
export type accountEditScreen_UpdateMutation$variables = {
  input: SetAccountNameInput;
};
export type accountEditScreen_UpdateMutation$data = {
  readonly Admin: {
    readonly Auth: {
      readonly setAccountName: {
        readonly account: {
          readonly " $fragmentSpreads": FragmentRefs<"accountEditScreen_AccountFragment">;
        };
      } | null;
    };
  };
};
export type accountEditScreen_UpdateMutation = {
  response: accountEditScreen_UpdateMutation$data;
  variables: accountEditScreen_UpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  (v2/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "accountEditScreen_UpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetAccountNamePayload",
                "kind": "LinkedField",
                "name": "setAccountName",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "accountEditScreen_AccountFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "accountEditScreen_UpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetAccountNamePayload",
                "kind": "LinkedField",
                "name": "setAccountName",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "registeredAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isDeleted",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AccountGroupAssociation",
                        "kind": "LinkedField",
                        "name": "groupAssociations",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AccountGroup",
                            "kind": "LinkedField",
                            "name": "group",
                            "plural": false,
                            "selections": (v4/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "accountExtensionKind",
                            "value": "Tka"
                          }
                        ],
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extension",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": (v6/*: any*/),
                                "concreteType": "UserInAccountConnection",
                                "kind": "LinkedField",
                                "name": "users",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "UserInAccountEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "UserInAccount",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v3/*: any*/),
                                          (v5/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "UserInAccountGroup",
                                            "kind": "LinkedField",
                                            "name": "groups",
                                            "plural": true,
                                            "selections": (v4/*: any*/),
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "kind": "LinkedField",
                                            "name": "user",
                                            "plural": false,
                                            "selections": [
                                              (v3/*: any*/),
                                              (v2/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "email",
                                                "storageKey": null
                                              },
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "activated",
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "cursor",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PageInfo",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "endCursor",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "hasNextPage",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ClientExtension",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__id",
                                        "storageKey": null
                                      }
                                    ]
                                  }
                                ],
                                "storageKey": "users(first:20)"
                              },
                              {
                                "alias": null,
                                "args": (v6/*: any*/),
                                "filters": null,
                                "handle": "connection",
                                "key": "AccountEditScreen_users",
                                "kind": "LinkedHandle",
                                "name": "users"
                              }
                            ],
                            "type": "TkaAccountExtension",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": "extension(accountExtensionKind:\"Tka\")"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "edb38e3d4d2cf9629fd2e2c3707e4502",
    "id": null,
    "metadata": {},
    "name": "accountEditScreen_UpdateMutation",
    "operationKind": "mutation",
    "text": "mutation accountEditScreen_UpdateMutation(\n  $input: SetAccountNameInput!\n) {\n  Admin {\n    Auth {\n      setAccountName(input: $input) {\n        account {\n          ...accountEditScreen_AccountFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment accountEditScreen_AccountFragment on Account {\n  name\n  id\n  registeredAt\n  isDeleted\n  groupAssociations {\n    group {\n      id\n      name\n    }\n  }\n  extension(accountExtensionKind: Tka) {\n    __typename\n    ... on TkaAccountExtension {\n      users(first: 20) {\n        edges {\n          node {\n            id\n            __typename\n          }\n          cursor\n        }\n        ...usersInAccountTable_UserInAccountConnectionFragment\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n  ...editAccountGroupsButton_AccountFragment\n}\n\nfragment editAccountGroupsButton_AccountFragment on Account {\n  ...editAccountGroupsForm_AccountFragment\n}\n\nfragment editAccountGroupsForm_AccountFragment on Account {\n  id\n  groupAssociations {\n    group {\n      id\n      name\n    }\n  }\n}\n\nfragment editUserInAccountGroup_UserInAccountFragment on UserInAccount {\n  groups {\n    id\n    name\n  }\n  user {\n    id\n  }\n}\n\nfragment usersInAccountTable_UserInAccountConnectionFragment on UserInAccountConnection {\n  edges {\n    node {\n      groups {\n        id\n        name\n      }\n      user {\n        id\n        name\n        email\n        activated\n      }\n      ...editUserInAccountGroup_UserInAccountFragment\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5126cdb0458b634aa7ada9ea3f5085b8";

export default node;
