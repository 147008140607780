import { Dropdown } from "primereact/dropdown";
import { DiscountTypeKind } from "@relay/ActionTypeColumn_DiscountActionFragment.graphql";
import { DiscountTypeInputProps } from "./discount-type-input.types";

export const DiscountTypeInput = ({ fieldValue, updateField }: DiscountTypeInputProps) => {
	const options: { label: string; value: DiscountTypeKind }[] = [
		{ label: "System", value: "System" },
		{ label: "User", value: "User" },
	];

	return (
		<Dropdown
			value={options.find((k) => k.value === fieldValue)?.value}
			options={options}
			placeholder="Discount-Typ"
			onChange={(e) => updateField(e.value)}
		/>
	);
};
