/**
 * @generated SignedSource<<afa46b5e1b7a1aecdde4911a08386ced>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderStatusType = "HasBillingDetails" | "HasCart" | "HasPaymentDetails" | "PaymentReceived" | "Purchased" | "Transient";
export type PaymentMethodType = "Card" | "IapApple" | "IapGoogle" | "InvoicePartner" | "InvoiceTk" | "Klarna" | "MonthlyPartner" | "MonthlyTk" | "Paypal" | "Sepa" | "Sofort";
export type PaymentProviderDataKind = "Apple" | "Default" | "Sepa" | "Stripe" | "StripeSepa";
import { FragmentRefs } from "relay-runtime";
export type paymentProviderLink_OrderFragment$data = {
  readonly paymentData: {
    readonly dataType: PaymentProviderDataKind;
    readonly paymentIntentId?: string;
    readonly paymentMethod: PaymentMethodType;
  } | null;
  readonly status: OrderStatusType;
  readonly " $fragmentType": "paymentProviderLink_OrderFragment";
};
export type paymentProviderLink_OrderFragment$key = {
  readonly " $data"?: paymentProviderLink_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"paymentProviderLink_OrderFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "paymentIntentId",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "paymentProviderLink_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "paymentData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentMethod",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dataType",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "StripePaymentProviderData",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "StripeSepaPaymentProviderData",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};
})();

(node as any).hash = "77f41aa847d510b144e14c8d6b5103af";

export default node;
