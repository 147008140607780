import { graphql } from "babel-plugin-relay/macro";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { Suspense, useRef } from "react";
import { useFragment, useMutation } from "react-relay";
import * as Yup from "yup";
import { type DiscountCodeForm_DiscountCodeFragment$key } from "@relay/DiscountCodeForm_DiscountCodeFragment.graphql";
import { type DiscountCodeForm_EditCodeMutation } from "@relay/DiscountCodeForm_EditCodeMutation.graphql";
import { DefaultTextFieldComponent } from "../../../components/DefaultTextInput";
import { ValidatedField } from "../../../components/ValidatedField";

const DISCOUNT_CODE_FRAGMENT = graphql`
	fragment DiscountCodeForm_DiscountCodeFragment on DiscountCode {
		id
		code
	}
`;

const EDIT_CODE_MUTATION = graphql`
	mutation DiscountCodeForm_EditCodeMutation($input: EditDiscountCodeInput!) {
		Admin {
			Billing {
				editDiscountCode(input: $input) {
					edge {
						node {
							...DiscountCodeForm_DiscountCodeFragment
						}
					}
				}
			}
		}
	}
`;

type DiscountCode = {
	id: string;
	code: string;
};

type OwnProps = {
	discountCodeFragmentRef: DiscountCodeForm_DiscountCodeFragment$key;
};

export const DiscountCodeForm = ({ discountCodeFragmentRef }: OwnProps) => {
	const toast = useRef<Toast>(null);

	const discountCode = useFragment<DiscountCodeForm_DiscountCodeFragment$key>(
		DISCOUNT_CODE_FRAGMENT,
		discountCodeFragmentRef,
	);

	const [editActionRaw, isLoading] =
		useMutation<DiscountCodeForm_EditCodeMutation>(EDIT_CODE_MUTATION);

	const formik = useFormik<DiscountCode>({
		initialValues: {
			id: discountCode.id,
			code: discountCode.code,
		},
		validationSchema: Yup.object().shape({}),
		onSubmit: (values, { setSubmitting }) => {
			editActionRaw({
				variables: {
					input: {
						discountCodeId: discountCode.id,
						newCode: values.code,
					},
				},
				onCompleted: () => {
					toast.current?.show({
						severity: "success",
						life: 3000,
						summary: "Discount-Aktion",
						detail: "Erfolgreich gespeichert",
					});
				},
			});
			setSubmitting(false);
		},
	});

	return (
		<Card>
			<Toast ref={toast} />
			<Suspense fallback={<ProgressSpinner />}>
				{!isLoading ? (
					<form onSubmit={formik.handleSubmit} className="p-fluid">
						<div className="flex flex-column">
							<Button
								disabled={false}
								type="submit"
								icon="pi pi-save"
								label="Speichern"
								className="ml-auto w-8rem"
							/>
							<ValidatedField<DiscountCode, string>
								name={"code"}
								label="Code"
								required={true}
								component={DefaultTextFieldComponent}
								formikConfig={formik}
							/>
						</div>
					</form>
				) : (
					<ProgressSpinner />
				)}
			</Suspense>
		</Card>
	);
};
