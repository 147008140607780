import React from "react";
import { useFragment } from "react-relay";
import { match } from "ts-pattern";
import { type orderHistoryEvent_OrderHistoryFragment$key } from "@relay/orderHistoryEvent_OrderHistoryFragment.graphql";
import { ORDER_HISTORY_FRAGMENT } from "./order-history-event.graphql";
import { type OrderHistoryEventProps } from "./order-history-event.types";
import { OrderHistoryEventDisplay } from "../order-history-event-display/order-history-event-display.component";

export const OrderHistoryEvent = ({ orderHistoryFragmentRef }: OrderHistoryEventProps) => {
	const orderHistory = useFragment<orderHistoryEvent_OrderHistoryFragment$key>(
		ORDER_HISTORY_FRAGMENT,
		orderHistoryFragmentRef,
	);

	return match(orderHistory.kind)
		.with(
			"OrderCreated",
			"CartUpdate",
			"CustomerDetailsUpdate",
			"PaymentMethod",
			"PaymentInProcess",
			"Purchase",
			() => <OrderHistoryEventDisplay event={orderHistory.kind} />,
		)
		.with("InvoiceSent", () => (
			<OrderHistoryEventDisplay event={orderHistory.kind}>
				<div>
					<div>Rechnungs ID:</div>
					<div>{orderHistory.invoiceId}</div>
				</div>
				<div>
					<div>Rechnungsnummer:</div>
					<div>{orderHistory.invoiceNumber}</div>
				</div>
			</OrderHistoryEventDisplay>
		))
		.exhaustive();
};
