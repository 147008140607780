/**
 * @generated SignedSource<<50ec5b46befde882d6287ace4def2a2e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type licensesTable_LicensesFragment$data = {
  readonly Admin: {
    readonly Auth: {
      readonly SelectAccounts: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"licensesTable_AccountFragment">;
          };
        } | null> | null;
      };
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"createLicensesButton_QueryFragment">;
  readonly " $fragmentType": "licensesTable_LicensesFragment";
};
export type licensesTable_LicensesFragment$key = {
  readonly " $data"?: licensesTable_LicensesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"licensesTable_LicensesFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = [
  "Admin",
  "Auth",
  "SelectAccounts"
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "nameOpt"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v2/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v2/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./licensesTable_Refetch.graphql')
    }
  },
  "name": "licensesTable_LicensesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminQueryType",
      "kind": "LinkedField",
      "name": "Admin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AuthAdminSchema",
          "kind": "LinkedField",
          "name": "Auth",
          "plural": false,
          "selections": [
            {
              "alias": "SelectAccounts",
              "args": [
                {
                  "kind": "Literal",
                  "name": "ids",
                  "value": []
                },
                {
                  "kind": "Variable",
                  "name": "nameOpt",
                  "variableName": "nameOpt"
                }
              ],
              "concreteType": "AccountConnection",
              "kind": "LinkedField",
              "name": "__licensesTable_SelectAccounts_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AccountEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Account",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "licensesTable_AccountFragment",
                          "selections": [
                            (v3/*: any*/),
                            (v4/*: any*/),
                            {
                              "alias": null,
                              "args": [
                                {
                                  "kind": "Literal",
                                  "name": "accountExtensionKind",
                                  "value": "Tka"
                                }
                              ],
                              "concreteType": null,
                              "kind": "LinkedField",
                              "name": "extension",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    {
                                      "alias": "licenses",
                                      "args": null,
                                      "concreteType": "LicenseConnection",
                                      "kind": "LinkedField",
                                      "name": "__licensesTable_licenses_connection",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "LicenseEdge",
                                          "kind": "LinkedField",
                                          "name": "edges",
                                          "plural": true,
                                          "selections": [
                                            {
                                              "alias": null,
                                              "args": null,
                                              "concreteType": "License",
                                              "kind": "LinkedField",
                                              "name": "node",
                                              "plural": false,
                                              "selections": [
                                                {
                                                  "kind": "InlineDataFragmentSpread",
                                                  "name": "licensesTable_LicenseFragment",
                                                  "selections": [
                                                    (v3/*: any*/),
                                                    {
                                                      "alias": null,
                                                      "args": null,
                                                      "concreteType": null,
                                                      "kind": "LinkedField",
                                                      "name": "creationInfo",
                                                      "plural": false,
                                                      "selections": [
                                                        (v5/*: any*/),
                                                        {
                                                          "alias": null,
                                                          "args": null,
                                                          "kind": "ScalarField",
                                                          "name": "createdAt",
                                                          "storageKey": null
                                                        },
                                                        {
                                                          "alias": null,
                                                          "args": null,
                                                          "concreteType": "LicenseDefinition",
                                                          "kind": "LinkedField",
                                                          "name": "licenseDefinition",
                                                          "plural": false,
                                                          "selections": [
                                                            {
                                                              "alias": null,
                                                              "args": null,
                                                              "concreteType": null,
                                                              "kind": "LinkedField",
                                                              "name": "data",
                                                              "plural": false,
                                                              "selections": [
                                                                (v4/*: any*/)
                                                              ],
                                                              "storageKey": null
                                                            }
                                                          ],
                                                          "storageKey": null
                                                        },
                                                        {
                                                          "kind": "InlineFragment",
                                                          "selections": [
                                                            {
                                                              "alias": null,
                                                              "args": null,
                                                              "kind": "ScalarField",
                                                              "name": "orderId",
                                                              "storageKey": null
                                                            }
                                                          ],
                                                          "type": "LicenseCreatedByOrder",
                                                          "abstractKey": null
                                                        },
                                                        {
                                                          "kind": "InlineFragment",
                                                          "selections": [
                                                            {
                                                              "alias": null,
                                                              "args": null,
                                                              "kind": "ScalarField",
                                                              "name": "creatorId",
                                                              "storageKey": null
                                                            }
                                                          ],
                                                          "type": "LicenseCreatedByAdmin",
                                                          "abstractKey": null
                                                        },
                                                        {
                                                          "kind": "InlineFragment",
                                                          "selections": [
                                                            {
                                                              "alias": null,
                                                              "args": null,
                                                              "kind": "ScalarField",
                                                              "name": "userId",
                                                              "storageKey": null
                                                            }
                                                          ],
                                                          "type": "LicenseCreatedByRegistration",
                                                          "abstractKey": null
                                                        }
                                                      ],
                                                      "storageKey": null
                                                    },
                                                    {
                                                      "alias": null,
                                                      "args": null,
                                                      "concreteType": null,
                                                      "kind": "LinkedField",
                                                      "name": "usage",
                                                      "plural": false,
                                                      "selections": [
                                                        (v5/*: any*/),
                                                        {
                                                          "kind": "InlineFragment",
                                                          "selections": [
                                                            {
                                                              "alias": null,
                                                              "args": null,
                                                              "kind": "ScalarField",
                                                              "name": "at",
                                                              "storageKey": null
                                                            },
                                                            {
                                                              "alias": null,
                                                              "args": null,
                                                              "concreteType": "User",
                                                              "kind": "LinkedField",
                                                              "name": "forUser",
                                                              "plural": false,
                                                              "selections": [
                                                                {
                                                                  "alias": null,
                                                                  "args": null,
                                                                  "kind": "ScalarField",
                                                                  "name": "email",
                                                                  "storageKey": null
                                                                }
                                                              ],
                                                              "storageKey": null
                                                            }
                                                          ],
                                                          "type": "LicenseUsageUsedForTree",
                                                          "abstractKey": null
                                                        }
                                                      ],
                                                      "storageKey": null
                                                    }
                                                  ],
                                                  "args": null,
                                                  "argumentDefinitions": []
                                                },
                                                (v6/*: any*/)
                                              ],
                                              "storageKey": null
                                            },
                                            (v7/*: any*/)
                                          ],
                                          "storageKey": null
                                        },
                                        (v8/*: any*/),
                                        {
                                          "kind": "ClientExtension",
                                          "selections": [
                                            {
                                              "alias": null,
                                              "args": null,
                                              "kind": "ScalarField",
                                              "name": "__id",
                                              "storageKey": null
                                            }
                                          ]
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "type": "TkaAccountExtension",
                                  "abstractKey": null
                                }
                              ],
                              "storageKey": "extension(accountExtensionKind:\"Tka\")"
                            }
                          ],
                          "args": [
                            {
                              "kind": "Variable",
                              "name": "after",
                              "variableName": "after"
                            },
                            {
                              "kind": "Variable",
                              "name": "first",
                              "variableName": "first"
                            }
                          ],
                          "argumentDefinitions": [
                            (v0/*: any*/),
                            (v1/*: any*/)
                          ]
                        },
                        (v6/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v7/*: any*/)
                  ],
                  "storageKey": null
                },
                (v8/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "createLicensesButton_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "1e0ebd760bed07368c6311231469cffc";

export default node;
