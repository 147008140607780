import { match } from "ts-pattern";
import CardImage from "@assets/payment-method/card.png";
import InvoiceImage from "@assets/payment-method/invoice.png";
import PaypalImage from "@assets/payment-method/paypal.png";
import SepaImage from "@assets/payment-method/sepa.png";
import SofortImage from "@assets/payment-method/sofort.png";
import { PaymentMethod } from "@translations/PaymentMethod";
import { PaymentMethodIcon, PaymentMethodImage } from "./payment-method-display.styles";
import { type PaymentMethodDisplayProps } from "./payment-method-display.types";

export const PaymentMethodDisplay = ({ paymentMethod }: PaymentMethodDisplayProps) => {
	const isIAP = paymentMethod === "IapGoogle" || paymentMethod === "IapApple";

	const Image = match(paymentMethod)
		.with("Card", () => CardImage)
		.with("Paypal", () => PaypalImage)
		.with("Sofort", () => SofortImage)
		.with("MonthlyTk", "MonthlyPartner", "Sepa", () => SepaImage)
		.with("InvoiceTk", "InvoicePartner", () => InvoiceImage)
		.otherwise(() => InvoiceImage);

	return (
		<div className="flex align-items-center m-1">
			{isIAP ? (
				<PaymentMethodIcon className="pi pi-shopping-cart" />
			) : (
				<PaymentMethodImage src={Image} alt={Image} />
			)}

			<div>{PaymentMethod[paymentMethod]}</div>
		</div>
	);
};
