/**
 * @generated SignedSource<<43bff0133864915270bdfba742308bd7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Billing_CustomerDetailsDataKind = "Business" | "Private";
import { FragmentRefs } from "relay-runtime";
export type billingDetailsForm_Billing_CustomerDetailsFragment$data = {
  readonly data: {
    readonly kind: Billing_CustomerDetailsDataKind;
  };
  readonly " $fragmentSpreads": FragmentRefs<"businessBillingDetailsForm_Billing_CustomerDetailsFragment" | "privateBillingDetailsForm_Billing_CustomerDetailsFragment">;
  readonly " $fragmentType": "billingDetailsForm_Billing_CustomerDetailsFragment";
};
export type billingDetailsForm_Billing_CustomerDetailsFragment$key = {
  readonly " $data"?: billingDetailsForm_Billing_CustomerDetailsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"billingDetailsForm_Billing_CustomerDetailsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "billingDetailsForm_Billing_CustomerDetailsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "privateBillingDetailsForm_Billing_CustomerDetailsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "businessBillingDetailsForm_Billing_CustomerDetailsFragment"
    }
  ],
  "type": "Billing_CustomerDetails",
  "abstractKey": null
};

(node as any).hash = "e0353ea8ada7cc8b007128418eea8dc9";

export default node;
