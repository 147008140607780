/**
 * @generated SignedSource<<ad2ad48800bfcd869ca81ccf2ef17251>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Billing_Salutation = "Divers" | "Mr" | "Mrs" | "Unknown";
export type CountryCode = "DE";
export type PrivateBaseDataInput = {
  city: string;
  countryCode: CountryCode;
  firstName: string;
  houseNumber: string;
  lastName: string;
  phoneNumber?: string | null;
  postalCode: string;
  salutation: Billing_Salutation;
  street: string;
  title?: string | null;
};
export type accountEditBaseDataScreen_EditPrivateBaseDataMutation$variables = {
  accountId: string;
  baseData: PrivateBaseDataInput;
};
export type accountEditBaseDataScreen_EditPrivateBaseDataMutation$data = {
  readonly Admin: {
    readonly AccountBaseData: {
      readonly editPrivateBaseData: {
        readonly baseData: {
          readonly __typename: "PrivateBaseData";
          readonly " $fragmentSpreads": FragmentRefs<"privateBaseDataForm_PrivateBaseDataFragment">;
        };
      } | null;
    };
  };
};
export type accountEditBaseDataScreen_EditPrivateBaseDataMutation = {
  response: accountEditBaseDataScreen_EditPrivateBaseDataMutation$data;
  variables: accountEditBaseDataScreen_EditPrivateBaseDataMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "baseData"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "accountId",
        "variableName": "accountId"
      },
      {
        "kind": "Variable",
        "name": "baseData",
        "variableName": "baseData"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "accountEditBaseDataScreen_EditPrivateBaseDataMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountBaseDataAdminMutationSchema",
            "kind": "LinkedField",
            "name": "AccountBaseData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditPrivateBaseDataAdminPayload",
                "kind": "LinkedField",
                "name": "editPrivateBaseData",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PrivateBaseData",
                    "kind": "LinkedField",
                    "name": "baseData",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "privateBaseDataForm_PrivateBaseDataFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "accountEditBaseDataScreen_EditPrivateBaseDataMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountBaseDataAdminMutationSchema",
            "kind": "LinkedField",
            "name": "AccountBaseData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditPrivateBaseDataAdminPayload",
                "kind": "LinkedField",
                "name": "editPrivateBaseData",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PrivateBaseData",
                    "kind": "LinkedField",
                    "name": "baseData",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "salutation",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "phoneNumber",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "city",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "street",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "houseNumber",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "postalCode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "countryCode",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "898eae854cd4b8593159fc2d7942a01b",
    "id": null,
    "metadata": {},
    "name": "accountEditBaseDataScreen_EditPrivateBaseDataMutation",
    "operationKind": "mutation",
    "text": "mutation accountEditBaseDataScreen_EditPrivateBaseDataMutation(\n  $accountId: ID!\n  $baseData: PrivateBaseDataInput!\n) {\n  Admin {\n    AccountBaseData {\n      editPrivateBaseData(input: {accountId: $accountId, baseData: $baseData}) {\n        baseData {\n          __typename\n          ...privateBaseDataForm_PrivateBaseDataFragment\n        }\n      }\n    }\n  }\n}\n\nfragment privateBaseDataForm_PrivateBaseDataFragment on PrivateBaseData {\n  __typename\n  salutation\n  title\n  firstName\n  lastName\n  phoneNumber\n  city\n  street\n  houseNumber\n  postalCode\n  countryCode\n}\n"
  }
};
})();

(node as any).hash = "11b2d8fbabc6c34e50e1250fd6d21f9c";

export default node;
