import moment from "moment-timezone";
import { DatetimeDisplayProps } from "./datetime-display.types";
import { formatDateTime } from "./datetime-display.util";

export const DateTimeDisplay = ({ value, label, hideTimezone }: DatetimeDisplayProps) => {
	if (!value) {
		return null;
	}

	const formattedDateTime = formatDateTime(value);

	if (hideTimezone) {
		return <span>{formattedDateTime}</span>;
	}

	return (
		<div>
			<label htmlFor={value} className="col-fixed">
				{label}
			</label>
			<div>{formattedDateTime}</div>
			{!hideTimezone && <small>{moment.tz.guess()}</small>}
		</div>
	);
};
