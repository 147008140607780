import graphql from "babel-plugin-relay/macro";

export const BUSINESS_BILLING_DETAILS_FRAGMENT = graphql`
	fragment businessBillingDetailsForm_Billing_CustomerDetailsFragment on Billing_CustomerDetails {
		firstName
		lastName
		street
		houseNumber
		postalCode
		city
		country
		salutation
		invoiceEmail
		data {
			... on Billing_BusinessCustomerDetails {
				companyDetails
				company
				companyType
			}
		}
	}
`;
