import { Divider } from "primereact/divider";
import { useFragment } from "react-relay";
import {
	type paymentDataForm_OrderFragment$key,
	type PaymentMethodType,
} from "@relay/paymentDataForm_OrderFragment.graphql";
import { H3Span } from "@themes/font-tags";
import { ORDER_FRAGMENT } from "./payment-data-form.graphql";
import { wrapperClass } from "./payment-data-form.styles";
import { type PaymentDataFormProps } from "./payment-data-form.types";
import { PaymentMethodDisplay } from "../payment-method-display";

export const PaymentDataForm = ({ orderFragmentRef }: PaymentDataFormProps) => {
	const order = useFragment<paymentDataForm_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef);

	return (
		<div className={wrapperClass}>
			<div>
				<H3Span>Erlaubte Zahlmethoden</H3Span>
				{order.allowedPaymentMethods.paymentMethods.map(
					(paymentMethod: PaymentMethodType, index: number) => (
						<PaymentMethodDisplay key={index} paymentMethod={paymentMethod} />
					),
				)}
			</div>
			<Divider layout="vertical" />
			<div>
				{order.selectedPaymentMethod?.paymentMethodType && (
					<>
						<H3Span>Gewählte Zahlmethode</H3Span>
						<PaymentMethodDisplay
							paymentMethod={order.selectedPaymentMethod?.paymentMethodType}
						/>
					</>
				)}
			</div>
		</div>
	);
};
