import { type Billing_CompanyType } from "@relay/accountEditBaseDataScreen_EditBusinessBaseDataMutation.graphql";

export enum BusinessTypeOptions {
	Ag = "Ag",
	Einzelunternehmen = "Einzelunternehmen",
	Ev = "Ev",
	Gbr = "Gbr",
	Gmbh = "Gmbh",
	GmbhCoKg = "GmbhCoKg",
	Kg = "Kg",
	OeffentlicheEinrichtung = "OeffentlicheEinrichtung",
	Ohg = "Ohg",
	Ug = "Ug",
	PartmbH = "PartmbH",
}

export type BusinessType = Billing_CompanyType;

export type BusinessTypeOptionsRecord = Record<BusinessType, string>;
