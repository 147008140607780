/**
 * @generated SignedSource<<3a6a3eefb797cab120ad397eb5e77ae5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type invoiceDataForm_OrderFragment$data = {
  readonly invoiceData: {
    readonly invoiceFile?: {
      readonly url: string | null;
    } | null;
    readonly invoiceId?: string;
    readonly invoiceNumber?: string;
  };
  readonly " $fragmentType": "invoiceDataForm_OrderFragment";
};
export type invoiceDataForm_OrderFragment$key = {
  readonly " $data"?: invoiceDataForm_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"invoiceDataForm_OrderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "invoiceDataForm_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "invoiceData",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "invoiceNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "invoiceId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "File",
              "kind": "LinkedField",
              "name": "invoiceFile",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "CreatedInvoiceData",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};

(node as any).hash = "151c39df789d59c0fd764b2b5304991d";

export default node;
