import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ActionCalcTypeColumn_DiscountActionFragment$key } from "@relay/ActionCalcTypeColumn_DiscountActionFragment.graphql";

const DISCOUNT_ACTION_FRAGMENT = graphql`
	fragment ActionCalcTypeColumn_DiscountActionFragment on DiscountAction {
		percent
	}
`;

interface OwnProps {
	discountActionFragmentRef: ActionCalcTypeColumn_DiscountActionFragment$key;
}

export const ActionCalcTypeColumn = ({ discountActionFragmentRef }: OwnProps) => {
	const discountAction = useFragment<ActionCalcTypeColumn_DiscountActionFragment$key>(
		DISCOUNT_ACTION_FRAGMENT,
		discountActionFragmentRef,
	);

	return <div>{discountAction.percent}%</div>;
};
