import { Button } from "primereact/button";
import { type DataTableSelectionSingleChangeEvent } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import React, { useCallback, useEffect, useState } from "react";
import { useLazyLoadQuery, usePaginationFragment } from "react-relay";
import { type selectDiscountCodeField_Query } from "@relay/selectDiscountCodeField_Query.graphql";
import { type selectDiscountCodeField_QueryFragment$key } from "@relay/selectDiscountCodeField_QueryFragment.graphql";
import { type selectDiscountCodeField_Refetch } from "@relay/selectDiscountCodeField_Refetch.graphql";
import { QUERY, QUERY_FRAGMENT } from "./select-discount-code-field.graphql";
import {
	type SelectDiscountCodeFieldProps,
	type SelectDiscountCodeFieldState,
} from "./select-discount-code-field.interface";
import { MinimizedDiscountCodeTable } from "../minimized-discount-code-table";

export const SelectDiscountCodeField = ({
	updateField,
	fieldValue: discountCode,
}: SelectDiscountCodeFieldProps) => {
	const query = useLazyLoadQuery<selectDiscountCodeField_Query>(QUERY, {});

	const {
		data: {
			Admin: {
				Billing: { searchDiscountCodes: discountCodes },
			},
		},
		hasPrevious,
		hasNext,
		refetch,
		loadPrevious,
		loadNext,
	} = usePaginationFragment<
		selectDiscountCodeField_Refetch,
		selectDiscountCodeField_QueryFragment$key
	>(QUERY_FRAGMENT, query);

	const [filterState, setFilterState] = useState<SelectDiscountCodeFieldState>({
		code: undefined,
	});

	const updateDiscountCodes = useCallback(
		(selectProductFieldState: SelectDiscountCodeFieldState) => {
			refetch({
				code: selectProductFieldState.code,
			});
		},
		[],
	);

	const handleOnSubmit = useCallback(() => {
		updateDiscountCodes(filterState);
	}, [filterState, updateDiscountCodes]);

	useEffect(handleOnSubmit, []);

	useEffect(() => {
		if (
			discountCode?.id &&
			!discountCodes.edges?.find((e) => e?.node.id === discountCode?.id)
		) {
			updateField(undefined);
		}
	}, [discountCodes]);

	const handleClearFormOnClick = () => {
		setFilterState({
			code: undefined,
		});
		updateDiscountCodes({});
	};

	const handleFilterCodeOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setFilterState({ ...filterState, code: e.target.value });
	}, []);

	const handleRootSelected = useCallback((event: DataTableSelectionSingleChangeEvent<any>) => {
		updateField({
			id: event.value.id,
			code: event.value.code,
		});
	}, []);

	return (
		<>
			<div className="flex flex-row align-items-center">
				<InputText
					value={filterState.code}
					placeholder={"code"}
					onChange={handleFilterCodeOnChange}
					className="mr-2"
				/>
				<Button
					disabled={false}
					type="reset"
					onClick={handleClearFormOnClick}
					icon="pi pi-times"
					className="h-3rem w-3rem"
				/>
				<Button
					disabled={false}
					onClick={handleOnSubmit}
					type="button"
					icon="pi pi-search"
					className="h-3rem w-3rem ml-2"
				/>
			</div>
			<MinimizedDiscountCodeTable
				discountCodeV2ConnectionFragmentRef={discountCodes}
				hasPrevious={hasPrevious}
				loadPrevious={loadPrevious}
				hasNext={hasNext}
				loadNext={loadNext}
				selectionMode="single"
				onDiscountCodeSelected={handleRootSelected}
				selectedId={discountCode?.id}
			/>
		</>
	);
};
