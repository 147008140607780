import { useFragment } from "react-relay";
import { CurrencyDisplay } from "@components/CurrencyDisplay";
import type { productPriceColumn_ProductFragment$key } from "@relay/productPriceColumn_ProductFragment.graphql";
import { PRODUCT_FRAGMENT } from "./product-price-column.graphql";
import type { ProductPriceColumnProps } from "./product-price-column.types";

export const ProductPriceColumn = ({ productFragmentRef }: ProductPriceColumnProps) => {
	const product = useFragment<productPriceColumn_ProductFragment$key>(
		PRODUCT_FRAGMENT,
		productFragmentRef,
	);

	return (
		<div>
			<CurrencyDisplay value={product.netPrice} />
		</div>
	);
};
