/**
 * @generated SignedSource<<e79bc1783970e03440b2323949623fb9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type HistoryEventKind = "CartUpdate" | "CustomerDetailsUpdate" | "InvoiceSent" | "OrderCreated" | "PaymentInProcess" | "PaymentMethod" | "Purchase";
export type OrderStatusType = "HasBillingDetails" | "HasCart" | "HasPaymentDetails" | "PaymentReceived" | "Purchased" | "Transient";
import { FragmentRefs } from "relay-runtime";
export type orderHistoryTable_OrderHistoryFragment$data = {
  readonly createdAt: string;
  readonly event: ReadonlyArray<{
    readonly kind: HistoryEventKind;
    readonly " $fragmentSpreads": FragmentRefs<"orderHistoryEvent_OrderHistoryFragment">;
  }>;
  readonly id: string;
  readonly status: OrderStatusType;
  readonly " $fragmentType": "orderHistoryTable_OrderHistoryFragment";
};
export type orderHistoryTable_OrderHistoryFragment$key = {
  readonly " $data"?: orderHistoryTable_OrderHistoryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"orderHistoryTable_OrderHistoryFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "orderHistoryTable_OrderHistoryFragment"
};

(node as any).hash = "f0cea61a49bdd0b3a99b7b3c284c1477";

export default node;
