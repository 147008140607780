import { useFragment } from "react-relay";
import { type invoiceProviderLink_InvoiceDataFragment$key } from "@relay/invoiceProviderLink_InvoiceDataFragment.graphql";
import { INVOICE_DATA_FRAGMENT } from "./invoice-provider-link.graphql";
import { type InvoiceProviderLinkProps } from "./invoice-provider-link.types";

export const InvoiceProviderLink = ({ invoiceDataFragmentRef }: InvoiceProviderLinkProps) => {
	const invoiceData = useFragment<invoiceProviderLink_InvoiceDataFragment$key>(
		INVOICE_DATA_FRAGMENT,
		invoiceDataFragmentRef,
	);

	return (
		<a
			target="_blank"
			rel="noopener noreferrer"
			href={`https://app.lexoffice.de/permalink/invoices/view/${invoiceData.invoiceId}`}
		>
			{invoiceData.invoiceNumber}
		</a>
	);
};
