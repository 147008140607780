import { graphql } from "babel-plugin-relay/macro";

export const DISCOUNT_ACTION_FRAGMENT = graphql`
	fragment discountActionForm_DiscountActionFragment on DiscountAction {
		id
		title
		percent
		validUntil {
			kind
			... on ValidUntilDateTime {
				dateTime
			}
		}
		discountType {
			kind
		}
		usageLimitation {
			kind
			... on UsageLimited {
				kind
				maxAmountOfUsages {
					... on AmountLimited {
						max
					}
				}
				maxAmountOfUsagesPerAccount {
					... on AmountLimited {
						max
					}
				}
				onlyForAccountIds
				onlyForProductIds
			}
		}
	}
`;

export const EDIT_DISCOUNT_ACTION_MUTATION = graphql`
	mutation discountActionForm_EditDiscountActionMutation($input: EditDiscountActionInput!) {
		Admin {
			Billing {
				editDiscountAction(input: $input) {
					edge {
						node {
							...discountActionForm_DiscountActionFragment
						}
					}
				}
			}
		}
	}
`;
