import graphql from "babel-plugin-relay/macro";

export const INVOICE_DATA_FRAGMENT = graphql`
	fragment invoiceProviderLink_InvoiceDataFragment on InvoiceData {
		... on CreatedInvoiceData {
			invoiceId
			invoiceNumber
		}
	}
`;
