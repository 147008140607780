import graphql from "babel-plugin-relay/macro";

export const BILLING_DETAILS_FRAGMENT = graphql`
	fragment billingDetailsForm_Billing_CustomerDetailsFragment on Billing_CustomerDetails {
		data {
			kind
		}
		...privateBillingDetailsForm_Billing_CustomerDetailsFragment
		...businessBillingDetailsForm_Billing_CustomerDetailsFragment
	}
`;
