import { type businessBillingDetailsForm_Billing_CustomerDetailsFragment$key } from "@relay/businessBillingDetailsForm_Billing_CustomerDetailsFragment.graphql";
import React from "react";
import { useFragment } from "react-relay";
import { BUSINESS_BILLING_DETAILS_FRAGMENT } from "./business-billing-details-form.graphql";
import { type BusinessBillingDetailsFormProps } from "./business-billing-details-form.interface";
import { TextDisplayField } from "../../../../components/text-display-field/text-display-field.component";
import { CompanyType } from "../../../../translations/CompanyType";

export const BusinessBillingDetailsForm = ({
	businessBillingDetailsFragmentRef,
}: BusinessBillingDetailsFormProps) => {
	const businessBillingDetails =
		useFragment<businessBillingDetailsForm_Billing_CustomerDetailsFragment$key>(
			BUSINESS_BILLING_DETAILS_FRAGMENT,
			businessBillingDetailsFragmentRef,
		);
	return (
		<div className="p-4">
			<div className="formgrid grid">
				<TextDisplayField label="Vorname" value={businessBillingDetails.firstName} />
				<TextDisplayField label="Nachname" value={businessBillingDetails.lastName} />
			</div>
			<div className="formgrid grid">
				<TextDisplayField label="Straße" value={businessBillingDetails.street} />
				<TextDisplayField label="Hausnummer" value={businessBillingDetails.houseNumber} />
			</div>
			<div className="formgrid grid">
				<TextDisplayField label="Stadt/Ort" value={businessBillingDetails.city} />
				<TextDisplayField label="Postleitzahl" value={businessBillingDetails.postalCode} />
			</div>
			<div className="formgrid grid">
				<TextDisplayField label="Land" value={businessBillingDetails.country} />
				<TextDisplayField label="Anrede" value={businessBillingDetails.salutation} />
			</div>
			<div className="formgrid grid">
				<TextDisplayField
					label="Rechnungs E-Mail"
					value={businessBillingDetails.invoiceEmail}
				/>
				<TextDisplayField
					label="Rechtsform"
					value={
						businessBillingDetails.data.companyType
							? CompanyType[businessBillingDetails.data.companyType]
							: undefined
					}
				/>
			</div>
			<div className="formgrid grid">
				<TextDisplayField
					label="Unternehmensname"
					value={businessBillingDetails.data.company}
				/>
				<TextDisplayField
					label="Unternehmensdetails"
					value={businessBillingDetails.data.companyDetails ?? undefined}
				/>
			</div>
		</div>
	);
};
