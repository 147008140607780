/**
 * @generated SignedSource<<9fcadbcb2b36f44701874cd8d116fb79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type HistoryEventKind = "CartUpdate" | "CustomerDetailsUpdate" | "InvoiceSent" | "OrderCreated" | "PaymentInProcess" | "PaymentMethod" | "Purchase";
import { FragmentRefs } from "relay-runtime";
export type orderHistoryEvent_OrderHistoryFragment$data = {
  readonly invoiceId?: string;
  readonly invoiceNumber?: string;
  readonly kind: HistoryEventKind;
  readonly " $fragmentType": "orderHistoryEvent_OrderHistoryFragment";
};
export type orderHistoryEvent_OrderHistoryFragment$key = {
  readonly " $data"?: orderHistoryEvent_OrderHistoryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"orderHistoryEvent_OrderHistoryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "orderHistoryEvent_OrderHistoryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceNumber",
          "storageKey": null
        }
      ],
      "type": "InvoiceSentEvent",
      "abstractKey": null
    }
  ],
  "type": "HistoryEvent",
  "abstractKey": "__isHistoryEvent"
};

(node as any).hash = "34ea5f7af7364f58ead4395c8e1fb2d1";

export default node;
