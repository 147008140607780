import React from "react";
import { readInlineData, useFragment } from "react-relay";
import { DateTimeDisplay } from "@components/datetime-display";
import { IdDisplayField } from "@components/id-display-field";
import { type orderHistoryTable_OrderHistoriesFragment$key } from "@relay/orderHistoryTable_OrderHistoriesFragment.graphql";
import { type orderHistoryTable_OrderHistoryFragment$key } from "@relay/orderHistoryTable_OrderHistoryFragment.graphql";
import { P2Span } from "@themes/font-tags";
import { OrderStatusType } from "@translations/OrderStatusType";
import { ORDER_HISTORIES_FRAGMENT, ORDER_HISTORY_FRAGMENT } from "./order-history-table.graphql";
import { type OrderHistoryTableProps } from "./order-history-table.types";

import { OrderHistoryEvent } from "../order-history-event";

export const OrderHistoryTable = ({ orderHistoriesFragmentRef }: OrderHistoryTableProps) => {
	const orders = useFragment<orderHistoryTable_OrderHistoriesFragment$key>(
		ORDER_HISTORIES_FRAGMENT,
		orderHistoriesFragmentRef,
	);

	const history = readInlineData<orderHistoryTable_OrderHistoryFragment$key>(
		ORDER_HISTORY_FRAGMENT,
		orders.node?.history!,
	);
	return (
		<div>
			<div>
				<P2Span>ID</P2Span>
				<IdDisplayField id={history.id} />
			</div>
			<div>
				<P2Span>Erstellungsdatum</P2Span>
				<DateTimeDisplay value={history.createdAt} hideTimezone />
			</div>
			<div>
				<P2Span>Status</P2Span>
				<div>{OrderStatusType[history.status]}</div>
			</div>
			{history.event.map((event) => {
				return <OrderHistoryEvent key={event.kind} orderHistoryFragmentRef={event} />;
			})}
		</div>
	);
};
