import * as Yup from "yup";
import { stripHtml } from "../../../../util/html.utils";

export const TextWithPagesElementPageFormSchema = Yup.object().shape({
	kind: Yup.string().required("Bitte gib eine Seiten-Art an."),
	internalTitle: Yup.string().required("Bitte gib einen Titel an."),
	image: Yup.object()
		.shape({
			id: Yup.string(),
		})
		.when("kind", {
			is: (kind: string) => {
				return (kind as unknown as string) === "ImagePageData";
			},
			then: (schema) =>
				schema.shape({
					id: Yup.string().required("Bitte wähle ein Bild aus."),
				}),
			otherwise: (schema) => schema.notRequired(),
		}),
	text: Yup.string()
		.transform((value) => {
			return stripHtml(value);
		})
		.max(1000, "Text zu lang (maximal 1000 Zeichen).")
		.when("kind", {
			is: (kind: string) => {
				return (kind as unknown as string) === "TextPageData";
			},
			then: (schema) => schema.required("Bitte gib einen Text an."),
			otherwise: (schema) => schema.notRequired(),
		}),
});
