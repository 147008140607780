/**
 * @generated SignedSource<<a3ccbcedb09929ee2656ac5b2fbce27e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type invoiceProviderLink_InvoiceDataFragment$data = {
  readonly invoiceId?: string;
  readonly invoiceNumber?: string;
  readonly " $fragmentType": "invoiceProviderLink_InvoiceDataFragment";
};
export type invoiceProviderLink_InvoiceDataFragment$key = {
  readonly " $data"?: invoiceProviderLink_InvoiceDataFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"invoiceProviderLink_InvoiceDataFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "invoiceProviderLink_InvoiceDataFragment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceNumber",
          "storageKey": null
        }
      ],
      "type": "CreatedInvoiceData",
      "abstractKey": null
    }
  ],
  "type": "InvoiceData",
  "abstractKey": "__isInvoiceData"
};

(node as any).hash = "a1882048afbb360b5b4481d4bac7c8bd";

export default node;
