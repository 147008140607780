/**
 * @generated SignedSource<<139c3a09419a017c36fad376fa7ee994>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type productPriceColumn_ProductFragment$data = {
  readonly netPrice: any;
  readonly " $fragmentType": "productPriceColumn_ProductFragment";
};
export type productPriceColumn_ProductFragment$key = {
  readonly " $data"?: productPriceColumn_ProductFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"productPriceColumn_ProductFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "productPriceColumn_ProductFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "netPrice",
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "6081f36b3421428389f55caabf24286b";

export default node;
