import graphql from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import React from "react";
import { useMutation } from "react-relay";
import { DeleteDiscountActionButton_DeleteMutation } from "@relay/DeleteDiscountActionButton_DeleteMutation.graphql";

const DELETE_DISCOUNT_ACTION_MUTATION = graphql`
	mutation DeleteDiscountActionButton_DeleteMutation($input: DeleteDiscountActionInput!) {
		Admin {
			Billing {
				deleteDiscountAction(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;

interface OwnProps {
	id: string;
	className?: string;
	connectionId: string;
}

export const DeleteDiscountActionButton = ({ className, id }: OwnProps) => {
	const [deleteDiscountAction, isLoading] =
		useMutation<DeleteDiscountActionButton_DeleteMutation>(DELETE_DISCOUNT_ACTION_MUTATION);

	return (
		<Button
			icon="pi pi-trash"
			tooltip="Löschen"
			loading={isLoading}
			disabled={isLoading}
			className={className}
			onClick={() => {
				deleteDiscountAction({
					variables: {
						input: {
							discountActionId: id,
						},
					},
				});
			}}
		/>
	);
};
