/**
 * @generated SignedSource<<227f75abdc140136442287a92912e08a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderStatusType = "HasBillingDetails" | "HasCart" | "HasPaymentDetails" | "PaymentReceived" | "Purchased" | "Transient";
import { FragmentRefs } from "relay-runtime";
export type orderPriceDisplay_OrderFragment$data = {
  readonly cart: {
    readonly totals: {
      readonly includingAllDiscounts: {
        readonly netPrice: any;
      };
    };
  } | null;
  readonly invoiceData: {
    readonly " $fragmentSpreads": FragmentRefs<"invoiceProviderLink_InvoiceDataFragment">;
  };
  readonly selectedPaymentMethod: {
    readonly chosenOption?: {
      readonly monthlyRate: {
        readonly netPrice: any;
      };
    };
  } | null;
  readonly status: OrderStatusType;
  readonly " $fragmentType": "orderPriceDisplay_OrderFragment";
};
export type orderPriceDisplay_OrderFragment$key = {
  readonly " $data"?: orderPriceDisplay_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"orderPriceDisplay_OrderFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "netPrice",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "orderPriceDisplay_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Totals",
          "kind": "LinkedField",
          "name": "totals",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Price",
              "kind": "LinkedField",
              "name": "includingAllDiscounts",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "selectedPaymentMethod",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MonthlyOption",
              "kind": "LinkedField",
              "name": "chosenOption",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Price",
                  "kind": "LinkedField",
                  "name": "monthlyRate",
                  "plural": false,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "MonthlyPaymentMethod",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "invoiceData",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "invoiceProviderLink_InvoiceDataFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};
})();

(node as any).hash = "ff3bc382adbd7c55630b08c4f2d0e666";

export default node;
