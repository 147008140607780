/**
 * @generated SignedSource<<2165dc1f5711149e139f24dea798ab88>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type productsTable_ProductFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProductActionColumn_ProductFragment" | "ProductIdColumn_ProductFragment" | "ProductIsHiddenColumn_ProductFragment" | "ProductTitleColumn_ProductFragment" | "productPriceColumn_ProductFragment">;
  readonly " $fragmentType": "productsTable_ProductFragment";
};
export type productsTable_ProductFragment$key = {
  readonly " $data"?: productsTable_ProductFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"productsTable_ProductFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "productsTable_ProductFragment"
};

(node as any).hash = "d9940f5f7c3576b313a8c1852765934a";

export default node;
