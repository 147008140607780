/**
 * @generated SignedSource<<83969ad6940a1ef6bacad603f6280267>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Billing_CompanyType = "Ag" | "Einzelunternehmen" | "Ev" | "Gbr" | "Gmbh" | "GmbhCoKg" | "Kg" | "OeffentlicheEinrichtung" | "Ohg" | "PartmbH" | "Ug" | "Unknown";
export type CountryCode = "DE";
export type BusinessBaseDataInput = {
  accountData: AccountDataInput;
  billingOffice: string;
  city: string;
  companyLegalForm: Billing_CompanyType;
  companyName: string;
  countryCode: CountryCode;
  houseNumber: string;
  invoiceEmail: string;
  phoneNumber: string;
  postalCode: string;
  street: string;
  taxData: TaxDataInput;
};
export type TaxDataInput = {
  taxIdentificationNumber: string;
  taxNumber: string;
};
export type AccountDataInput = {
  bic?: string | null;
  iban: string;
};
export type accountEditBaseDataScreen_EditBusinessBaseDataMutation$variables = {
  accountId: string;
  baseData: BusinessBaseDataInput;
};
export type accountEditBaseDataScreen_EditBusinessBaseDataMutation$data = {
  readonly Admin: {
    readonly AccountBaseData: {
      readonly editBusinessBaseData: {
        readonly baseData: {
          readonly __typename: "BusinessBaseData";
          readonly " $fragmentSpreads": FragmentRefs<"businessBaseDataForm_BusinessBaseDataFragment">;
        };
      } | null;
    };
  };
};
export type accountEditBaseDataScreen_EditBusinessBaseDataMutation = {
  response: accountEditBaseDataScreen_EditBusinessBaseDataMutation$data;
  variables: accountEditBaseDataScreen_EditBusinessBaseDataMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "baseData"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "accountId",
        "variableName": "accountId"
      },
      {
        "kind": "Variable",
        "name": "baseData",
        "variableName": "baseData"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "accountEditBaseDataScreen_EditBusinessBaseDataMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountBaseDataAdminMutationSchema",
            "kind": "LinkedField",
            "name": "AccountBaseData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditBusinessBaseDataAdminPayload",
                "kind": "LinkedField",
                "name": "editBusinessBaseData",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BusinessBaseData",
                    "kind": "LinkedField",
                    "name": "baseData",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "businessBaseDataForm_BusinessBaseDataFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "accountEditBaseDataScreen_EditBusinessBaseDataMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountBaseDataAdminMutationSchema",
            "kind": "LinkedField",
            "name": "AccountBaseData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditBusinessBaseDataAdminPayload",
                "kind": "LinkedField",
                "name": "editBusinessBaseData",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BusinessBaseData",
                    "kind": "LinkedField",
                    "name": "baseData",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "companyName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "billingOffice",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "companyLegalForm",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "invoiceEmail",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "phoneNumber",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "city",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "street",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "houseNumber",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "postalCode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "countryCode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AccountData",
                        "kind": "LinkedField",
                        "name": "accountData",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "bic",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "iban",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TaxData",
                        "kind": "LinkedField",
                        "name": "taxData",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "taxNumber",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "taxIdentificationNumber",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a663a7d900397f2291943e89d67a28a2",
    "id": null,
    "metadata": {},
    "name": "accountEditBaseDataScreen_EditBusinessBaseDataMutation",
    "operationKind": "mutation",
    "text": "mutation accountEditBaseDataScreen_EditBusinessBaseDataMutation(\n  $accountId: ID!\n  $baseData: BusinessBaseDataInput!\n) {\n  Admin {\n    AccountBaseData {\n      editBusinessBaseData(input: {accountId: $accountId, baseData: $baseData}) {\n        baseData {\n          __typename\n          ...businessBaseDataForm_BusinessBaseDataFragment\n        }\n      }\n    }\n  }\n}\n\nfragment businessBaseDataForm_BusinessBaseDataFragment on BusinessBaseData {\n  __typename\n  companyName\n  billingOffice\n  companyLegalForm\n  invoiceEmail\n  phoneNumber\n  city\n  street\n  houseNumber\n  postalCode\n  countryCode\n  accountData {\n    bic\n    iban\n  }\n  taxData {\n    taxNumber\n    taxIdentificationNumber\n  }\n}\n"
  }
};
})();

(node as any).hash = "502c5272294cb2a15d3659db0a971167";

export default node;
