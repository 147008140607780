import { useFragment } from "react-relay";
import { match } from "ts-pattern";
import { type paymentProviderLink_OrderFragment$key } from "@relay/paymentProviderLink_OrderFragment.graphql";
import { PaymentMethod } from "@translations/PaymentMethod";
import { ORDER_FRAGMENT } from "./payment-provider-link.graphql";
import { type PaymentProviderLinkProps } from "./payment-provider-link.types";

export const PaymentProviderLink = ({ orderFragmentRef }: PaymentProviderLinkProps) => {
	const order = useFragment<paymentProviderLink_OrderFragment$key>(
		ORDER_FRAGMENT,
		orderFragmentRef,
	);
	const isProduction = process.env.APP_ENVIRONMENT === "prod";

	if (order.status === "PaymentReceived" || order.status === "Purchased") {
		const paymentString = PaymentMethod[order.paymentData?.paymentMethod || ""];
		const paymentIntentId = order.paymentData?.paymentIntentId;

		return match(order.paymentData?.dataType)
			.with("Stripe", "StripeSepa", () => {
				if (isProduction && paymentIntentId) {
					return (
						<a
							rel="noopener noreferrer"
							target="_blank"
							href={`https://dashboard.stripe.com/payments/${paymentIntentId}`}
						>
							{paymentString}
						</a>
					);
				}
				if (paymentIntentId) {
					return (
						<a
							rel="noopener noreferrer"
							target="_blank"
							href={`https://dashboard.stripe.com/test/payments/${paymentIntentId}`}
						>
							{paymentString}
						</a>
					);
				}
				return <>{paymentString}</>;
			})
			.otherwise(() => <>{paymentString}</>);
	} else {
		return <>Unbezahlt</>;
	}
};
